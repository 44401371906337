@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.green-gradient {
  background-image: linear-gradient(251.38deg, rgba(141, 250, 255, 1) 0%, rgba(0, 224, 255, 1) 100%); 
}



.polygon {
  position:relative;
  overflow:hidden;
  background: transparent;
  clip-path: polygon(
    0 10%,
    10% 0,
    90% 0,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%
  )
}

@media (max-width: 1023px) {
  .lg\:overflow-x-scroll {
    overflow-x: auto !important; /* Enable horizontal scrolling */
    -ms-overflow-style: none; /* Hide scrollbar on Internet Explorer */
    scrollbar-width: none; /* Hide scrollbar on Firefox */
  }

  .lg\:overflow-x-scroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar on WebKit-based browsers */
  }
}
/* Hide scrollbar on medium screens (md) */
@media (max-width: 768px) {
  .md\:overflow-x-scroll {
    overflow-x: auto !important; /* Enable horizontal scrolling */
    -ms-overflow-style: none; /* Hide scrollbar on Internet Explorer */
    scrollbar-width: none; /* Hide scrollbar on Firefox */
  }

  .md\:overflow-x-scroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar on WebKit-based browsers */
  }
}

/* CSS animatiion start*/
.fade-in {
  opacity: 0;
  animation: fadeInAnimation 1s ease-in forwards;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUpAnimation 1s ease-in forwards;
}
@keyframes fadeInUpAnimation {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* css animation end/ */
html{
  scroll-behavior: smooth;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1C1C1C;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-custom-green {
  background-color: #B4FF7D !important;  /* New green color, overriding all other settings */
}

.text-custom-green {
  color: #FFFF00 !important;
}
.border-white {
  border-color:  #FFFF00 !important; ;
}

.roadmapHeadingColor  {
  color: #86FF00, 
   
};

.border-custom-green {
  border-color:  #86FF00 !important;
}

.font-zendots {
  font-family: "Zen Dots", sans-serif;
}

.bg-overlay-style-team{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #09121d, rgba(9, 18, 29, 0) 55.73%, #09121d), url(./assets/home/bg_homesky.png);
  mix-blend-mode: luminosity;
  opacity: .35;
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: cover;
  z-index: -1;
}

.bg-overlay-style-nft{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #09121d, rgba(9, 18, 29, 0) 55.73%, #09121d), url(./assets/home/bg-1.png);
  mix-blend-mode: luminosity;
  opacity: .35;
  background-repeat: no-repeat;
  background-position: 50%, 50%;
  background-size: cover;
  z-index: -1;
}

.project-box-style3 .header_project .image {
  margin-right: 14px;
  position: relative;
  height: 90px;
  width: 90px;
  z-index: 9;
  /* background: url(/_next/static/media/project_8.53d98810.png) 50% no-repeat; */
}

 